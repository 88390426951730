import { AuthChangeEvent, Session, User } from "@supabase/supabase-js";
import React from "react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate } from "react-router-dom";
import { useClient, useAuthStateChange } from "react-supabase";

const initialState: {
  session: null | Session;
  user: null | User;
  event: null | AuthChangeEvent;
} = { session: null, user: null, event: null };
export const AuthContext = createContext(initialState);

export function AuthProvider({ children }: { children: ReactNode }) {
  const client = useClient();
  const [auth, setAuth] = useState(initialState);

  useEffect(() => {
    const session = client.auth.session();
    setAuth({
      session,
      user: session?.user ?? null,
      event: null,
    });
  }, [client.auth]);

  useAuthStateChange((event, session) => {
    console.log(`Supabase auth event: ${event}`, session);
    setAuth({ session, user: session?.user ?? null, event });
  });

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw Error("useAuth must be used within AuthProvider");
  return context;
}

export function RequireAuth({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { user } = useAuth();

  return user === null ? <Navigate to="/sign-in" replace /> : children;
}
