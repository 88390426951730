import {
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useClient, useUpsert } from "react-supabase";
import { defaultDish, Dish } from "./data";

export const EditDish = React.forwardRef<
  HTMLDivElement,
  { closePopup: () => void; dish?: Dish }
>(function ({ closePopup, dish: editedDish }, ref) {
  const [dish, setDish] = useState<Dish>(editedDish ?? defaultDish);
  const [status, setStatus] = useState("idle");
  const [, updateDish] = useUpsert("dishes");
  const client = useClient();
  const [sourceFile, setSourceFile] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  async function saveDish() {
    const data = { ...dish };

    setStatus("loading");
    if (sourceFile) {
      const fileName = `source_${dish.title.replace(" ", "_")}.jpg`;
      const { error } = await client.storage
        .from("source-images")
        .upload(fileName, sourceFile);
      if (error) {
        setStatus(error.message);
      } else {
        data.source_image_link = fileName;
      }
    }
    if (imageFile) {
      const fileName = `${dish.title.replace(" ", "_")}.jpg`;
      const { error } = await client.storage
        .from("images")
        .upload(fileName, imageFile);
      if (error) {
        setStatus(error.message);
      } else {
        data.image_link = fileName;
      }
    }
    const { error } = await updateDish(data);
    if (error) {
      setStatus(error.message);
    } else {
      closePopup();
    }
  }

  return (
    <div ref={ref} className="p-4">
      <FormControl>
        {editedDish ? "Edit" : "Add New"} Dish
        <TextField
          margin="dense"
          value={dish.title}
          onChange={(event) =>
            setDish({
              ...dish,
              title: event.target.value,
            })
          }
          size="small"
          label="Title"
        />
        <TextField
          value={dish.source}
          margin="dense"
          onChange={(event) =>
            setDish({
              ...dish,
              source: event.target.value,
            })
          }
          size="small"
          label="Source, e.g. link or page number"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dish.needs_prep}
              onChange={(_, checked) =>
                setDish({ ...dish, needs_prep: checked })
              }
            />
          }
          label="Requires Preparation"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dish.is_quick}
              onChange={(_, checked) => setDish({ ...dish, is_quick: checked })}
            />
          }
          label="Is Quick"
        />
        Source image, i.e. picture of ingredients and steps
        <div className="pb-2">
          {window.innerWidth < 800 && (
            <Button component="label" variant="outlined">
              Take a picture
              <input
                hidden
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(event) => {
                  if (event.target.files) setSourceFile(event.target.files[0]);
                }}
              />
            </Button>
          )}
          <Button component="label" variant="outlined">
            Upload an image
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={(event) => {
                if (event.target.files) setSourceFile(event.target.files[0]);
              }}
            />
          </Button>
        </div>
        Image of food. Be precise 'bout it.
        <div className="pb-4">
          {window.innerWidth < 800 && (
            <Button component="label" variant="outlined">
              Take a picture
              <input
                hidden
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(event) => {
                  if (event.target.files) setImageFile(event.target.files[0]);
                }}
              />
            </Button>
          )}
          <Button component="label" variant="outlined">
            Upload an image
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={(event) => {
                if (event.target.files) setImageFile(event.target.files[0]);
              }}
            />
          </Button>
        </div>
        <Button onClick={saveDish} variant="contained">
          Save
        </Button>
        {status !== "idle" && <p>{status}</p>}
      </FormControl>
    </div>
  );
});
