import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useFilter, useRealtime, useSignOut } from "react-supabase";
import { DisplayDish } from "./Dish";
import { EditDish } from "./EditDish";
import { Button, Dialog, Grid, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Home() {
  const options = ["today", "tomorrow", "this week", "this weekend"];
  const [date, setDate] = useState("today");
  const [addDishVisible, setAddDishVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [, supabaseSignOut] = useSignOut();
  const open = Boolean(anchorEl);
  const filter = useFilter(
    (query) => {
      if (date === "this week") return query.limit(12);
      if (date === "this weekend") return query.limit(6);
      if (date === "today")
        return query.eq("needs_prep", false).eq("is_quick", true).limit(6);
      if (date === "tomorrow") return query.eq("is_quick", true).limit(6);
      return query;
    },
    [date]
  );
  const [result, updateDishes] = useRealtime("random_dishes", {
    select: { filter },
  });
  const navigate = useNavigate();

  const { data, fetching, error } = result;

  if (error) return <p>Oh no... {error.message}</p>;
  if (!data) return <p>No data</p>;

  async function signOut() {
    const { error } = await supabaseSignOut();
    if (error) console.log(error);
    navigate("/sign-in");
  }

  return (
    <div className="m-4">
      {/* Top Row */}
      <div className="flex items-center justify-between">
        <div className="md:text-xl flex flex-row">
          What should I cook
          <div className="md:pl-2 pl-1 md:w-40 w-32">
            <div
              className="border-b-2 border-black select-none cursor-pointer flex justify-between"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {date}
              <FontAwesomeIcon className="pt-1" icon={faAngleDown} />
            </div>
            <Menu open={open} anchorEl={anchorEl}>
              {options.map((option) => (
                <MenuItem
                  key={option}
                  className={clsx(
                    "cursor-pointer hover:bg-gray-200 px-1",
                    option === date && "bg-gray-200"
                  )}
                  onClick={() => {
                    setDate(option);
                    setAnchorEl(null);
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
        <div className="flex">
          <Button onClick={signOut}>Log Out</Button>
          <Button
            variant="outlined"
            onClick={() => setAddDishVisible(true)}
            className="!mr-2"
          >
            Add Dish
          </Button>
        </div>
      </div>

      {fetching ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          <Grid container spacing={2} className="py-2">
            {data.map((dish) => (
              <DisplayDish key={dish.title} dish={dish} />
            ))}
          </Grid>
          <div className="justify-center flex">
            <Button onClick={updateDishes} variant="outlined" className="!mr-2">
              Meh
            </Button>
            <Button onClick={updateDishes} variant="outlined" className="!mr-2">
              I'm not feeling it...
            </Button>
            <Button onClick={updateDishes} variant="outlined" className="!mr-2">
              Suggest me something else
            </Button>
          </div>
        </>
      )}

      <Dialog open={addDishVisible} onClose={() => setAddDishVisible(false)}>
        <EditDish closePopup={() => setAddDishVisible(false)} />
      </Dialog>
    </div>
  );
}
