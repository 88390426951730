import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { createClient } from "@supabase/supabase-js";
import { Provider } from "react-supabase";
import { Home } from "./Home";
import { ResetPassword } from "./ResetPassword";
import { SignInPage } from "./SignIn";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RequireAuth, AuthProvider } from "./Authentication";

const client = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_KEY as string
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  { path: "/sign-in", element: <SignInPage /> },
  { path: "/reset-password", element: <ResetPassword /> },
]);

root.render(
  <React.StrictMode>
    <Provider value={client}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);
