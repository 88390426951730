import { SupabaseClient } from "@supabase/supabase-js";

export interface Dish {
  title: string;
  image_link?: string;
  source: string;
  source_image_link?: string;
  needs_prep: boolean;
  is_quick: boolean;
}

export const defaultDish = {
  title: "",
  source: "",
  is_quick: false,
  needs_prep: false,
};

export const downloadImage = async (
  client: SupabaseClient,
  path: string,
  bucket = "images"
) => {
  const { data, error } = await client.storage.from(bucket).download(path);
  if (error) {
    console.log("Error downloading image: ", error.message);
    return null;
  } else if (data) {
    const url = URL.createObjectURL(data);
    return url ?? null;
  }
  return null;
};
