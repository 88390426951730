import React, { useEffect, useState } from "react";
import { useClient } from "react-supabase";
import { Dish, downloadImage } from "./data";
import { Box, Button, Dialog, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarCrash,
  faInfoCircle,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { EditDish } from "./EditDish";

export function DisplayDish({ dish }: { dish: Dish }) {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [openDescription, setOpenDescription] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const client = useClient();

  useEffect(() => {
    if (dish.image_link)
      downloadImage(client, dish.image_link).then(setImageUrl);
  }, [client, dish.image_link]);

  return (
    <Grid item xs={6} md={4} lg={3} xl={3}>
      <Box
        className="bg-cover bg-center bg-no-repeat aspect-video relative"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <p className="text-white text-lg p-2 font-bold">{dish.title}</p>
        {dish.is_quick && (
          <FontAwesomeIcon className="text-white p-2" icon={faCarCrash} />
        )}
        {dish.needs_prep && (
          <FontAwesomeIcon className="text-white p-2" icon={faSun} />
        )}
        <FontAwesomeIcon
          className="text-white bottom-2 right-2 absolute "
          onClick={() => setOpenDescription(true)}
          icon={faInfoCircle}
        />
      </Box>
      <Dialog open={openEdit} onClose={() => setOpenEdit(false)}>
        <EditDish dish={dish} closePopup={() => setOpenEdit(false)} />
      </Dialog>
      <Dialog open={openDescription} onClose={() => setOpenDescription(false)}>
        <DishHighlight
          dish={dish}
          onEdit={() => {
            setOpenDescription(false);
            setOpenEdit(true);
          }}
        />
      </Dialog>
    </Grid>
  );
}

const DishHighlight = React.forwardRef<
  HTMLDivElement,
  { dish: Dish; onEdit: () => void }
>(function ({ dish, onEdit }, ref) {
  const client = useClient();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  useEffect(() => {
    if (dish.source_image_link)
      downloadImage(client, dish.source_image_link, "source-images").then(
        setImageUrl
      );
  }, [dish.source_image_link, client]);

  return (
    <div ref={ref} className="p-2">
      <Button onClick={() => onEdit()}>Edit</Button>
      <p className="text-lg font-bold">{dish.title}</p>
      {dish.source && <p>{dish.source}</p>}
      {imageUrl && <img src={imageUrl} alt="recipe source" />}
    </div>
  );
});
