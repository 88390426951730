import { useClient } from "react-supabase";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useAuth } from "./Authentication";

export function ResetPassword() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const client = useClient();
  const { user } = useAuth();

  async function setNewPassword() {
    if (!validatePassword()) return;
    const { data, error } = await client.auth.update({ password });
    if (error) {
      setError(error.message);
    }
    if (data) {
      setMessage("Password updated, you can now log in");
      setPassword("");
    }
  }

  function validatePassword() {
    if (!password || password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      return false;
    }
    setPasswordError(null);
    return true;
  }

  //HOW DO I GET THE EMAIL?
  return (
    <div className="bg-white p-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md flex flex-col">
      <p className="text-xl">All You Can Cook</p>
      <p>{user?.email}</p>
      <TextField
        variant="outlined"
        label="Password"
        size="small"
        type="password"
        margin="dense"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        error={!!passwordError}
        helperText={passwordError}
      />
      <p className="text-red-800">{error}</p>
      <p className="text-green-600">{message}</p>
      <Button onClick={setNewPassword}>Set New Password</Button>
    </div>
  );
}
