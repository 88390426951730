import {
  useAuthStateChange,
  useResetPassword,
  useSignIn,
  useSignUp,
} from "react-supabase";
import React, { useState } from "react";
import { Button, Link, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function SignInPage() {
  const [, supabaseSignIn] = useSignIn();
  const [, supabaseSignUp] = useSignUp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, supabaseResetPassword] = useResetPassword();
  const [error, setError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  async function signUp() {
    if (!validateEmail()) return;
    if (!validatePassword()) return;
    const { error } = await supabaseSignUp({ email, password });
    if (error) {
      setError(error.message);
    } else {
      setMessage("Check your email for a confirmation link");
      setEmail("");
      setPassword("");
    }
  }

  async function signIn() {
    if (!validateEmail()) return;
    if (!validatePassword()) return;
    const { error } = await supabaseSignIn({ email, password });
    if (error) {
      setError(error.message);
    } else {
      navigate("/");
    }
  }

  async function sendPasswordEmail() {
    if (!validateEmail()) return;
    const { error } = await supabaseResetPassword(email, {
      redirectTo: "https://cook.corinnaj.me/reset-password",
    });
    if (error) {
      setError(error.message);
    } else {
      setMessage("Password reset email sent");
      setEmail("");
      setPassword("");
    }
  }

  function validateEmail() {
    setError(null);
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!email.includes("@")) {
      setEmailError("Email is invalid");
      return false;
    }
    setEmailError(null);
    return true;
  }

  function validatePassword() {
    if (!password || password.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      return false;
    }
    setPasswordError(null);
    return true;
  }

  return (
    <div className="bg-white p-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md flex flex-col">
      <p className="text-xl">All You Can Cook</p>
      <TextField
        variant="outlined"
        label="Email"
        size="small"
        value={email}
        margin="dense"
        onChange={(event) => setEmail(event.target.value)}
        error={!!emailError}
        helperText={emailError}
      />
      <TextField
        variant="outlined"
        label="Password"
        size="small"
        type="password"
        margin="dense"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        error={!!passwordError}
        helperText={passwordError}
      />
      <p className="text-red-800">{error}</p>
      <p className="text-green-600">{message}</p>
      <Link onClick={sendPasswordEmail} className="cursor-pointer">
        Forgot password...
      </Link>
      <Button variant="contained" onClick={signIn} className="!my-1">
        Sign In
      </Button>
      <Button variant="outlined" onClick={signUp} className="!my-1">
        Sign Up
      </Button>
    </div>
  );
}
